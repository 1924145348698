/* this file is transformed by vux-loader */
/* eslint-disable */
import { format, keepTwoDecimalFull } from "../../../assets/app.js";
export default {
  props: {
    dataInfo: {
      default: {}
    },
    showBntData: {
      default: ""
    }
  },
  data() {
    return {
      showBnt: false
    };
  },
  created() {
    if (this.showBntData == "显示") {
      this.showBnt = true;
    }
  },
  methods: {
    onProps() {
      this.datas = this.dataInfo;
      this.$emit("tremListInfo", this.datas);
    }
  }
};